import React, { useRef, useState } from 'react';
import './index.css';
import { Button } from 'antd';
import {
    RightOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import generate_images_bg_1 from '../../../images/generate_images_bg_1.png';
import generate_images_bg_2 from '../../../images/generate_images_bg_2.png';
import generate_images_bg_3 from '../../../images/generate_images_bg_3.png';
import home_styleid_bg from '../../../images/home_styleid_bg.png';
import home_faceid_bg from '../../../images/home_faceid_bg.png';
import home_duo_bg from '../../../images/home_duo_bg.png';

const ContentFour = () => {
    const { t } = useTranslation();
    const elementOneRef = useRef(null);
    const elementTwoRef = useRef(null);
    const elementThreeRef = useRef(null);
    const [isOneVisible,] = useState(false);
    const [isTwoVisible,] = useState(false);
    const [isThreeVisible,] = useState(false);
    const navigate = useNavigate();
  

    // //初始化从两边到中间移动动画
    // useEffect(() => {
    //     const observerOne = new IntersectionObserver(
    //         ([entry]) => {
    //             if (entry.isIntersecting) {
    //                 setIsOneVisible(true);
    //                 observerOne.disconnect(); // 一旦可见，停止观察
    //             }
    //         },
    //         { threshold: .5 } // 视口的 50% 进入时触发
    //     );
    //     const observerTwo = new IntersectionObserver(
    //         ([entry]) => {
    //             if (entry.isIntersecting) {
    //                 setIsTwoVisible(true);
    //                 observerTwo.disconnect(); // 一旦可见，停止观察
    //             }
    //         },
    //         { threshold: .5 } // 视口的 50% 进入时触发
    //     );
    //     const observerThree = new IntersectionObserver(
    //         ([entry]) => {
    //             if (entry.isIntersecting) {
    //                 setIsThreeVisible(true);
    //                 observerThree.disconnect(); // 一旦可见，停止观察
    //             }
    //         },
    //         { threshold: .5 } // 视口的 50% 进入时触发
    //     );
    
    //     elementOneRef.current && observerOne.observe(elementOneRef.current);
    //     elementTwoRef.current && observerTwo.observe(elementTwoRef.current);
    //     elementThreeRef.current && observerThree.observe(elementThreeRef.current);
  
    //     return () => {
    //         elementOneRef.current && observerOne.unobserve(elementOneRef.current); // 清理观察者
    //         elementTwoRef.current && observerTwo.unobserve(elementTwoRef.current); // 清理观察者
    //         elementThreeRef.current && observerThree.unobserve(elementThreeRef.current); // 清理观察者
    //     };
    // }, []);

    const onGenerateClick = (key) => {
        navigate(`/app/${key}`)
    };

    return (
        <div className='content-four'>
            <div className='content-four-one'>
                <div className='content-row' ref={elementOneRef}>
                    <div className={`content-row-img ${isOneVisible ? 'visible' : ''}`}>
                        <img src={home_styleid_bg} alt="" />
                    </div>
                    <div className={`content-row-text ${isOneVisible ? 'visible' : ''}`}>
                        <div className='text'>
                            <h2>{t('home.bodyone.title')}</h2>
                            <div>
                                <p>{t('home.bodyone.introduction')}</p>
                                <p>{t('home.bodyone.body')}</p>
                            </div>
                        </div>
                        <Button
                            type="primary"
                            shape="round"
                            className='but'
                            onClick={() => onGenerateClick('StyleID')}
                        >{t('home.bodyone.but')}</Button>
                    </div>
                </div>
            </div>
            <div className='content-four-two'>
                <div className='content-row' ref={elementTwoRef}>
                    <div className={`content-row-text ${isTwoVisible ? 'visible' : ''}`}>
                        <div className='text'>
                            <h2>{t('home.bodytwo.title')}</h2>
                            <div>
                                <p>{t('home.bodytwo.introduction')}</p>
                                <p>{t('home.bodytwo.body')}</p>
                            </div>
                        </div>
                        <Button
                            type="primary"
                            shape="round"
                            className='but'
                            onClick={() => onGenerateClick('FaceID')}
                        >{t('home.bodytwo.but')}</Button>
                    </div>
                    <div className={`content-row-img ${isTwoVisible ? 'visible' : ''}`}>
                        <img src={home_faceid_bg} alt=""/>
                    </div>
                </div>
            </div>
            <div className='content-four-three'>
                <div className='content-row' ref={elementThreeRef}>
                    <div className={`content-row-img ${isThreeVisible ? 'visible' : ''}`}>
                        <img src={home_duo_bg} alt=""/>
                    </div>
                    <div className={`content-row-text ${isThreeVisible ? 'visible' : ''}`}>
                        <div className='text'>
                            <h2>{t('home.bodythree.title')}</h2>
                            <div>
                                <p>{t('home.bodythree.introduction')}</p>
                                <p>{t('home.bodythree.body')}</p>
                            </div>
                        </div>
                        <Button
                            type="primary"
                            shape="round"
                            className='but'
                            onClick={() => onGenerateClick('Duo')}
                        >{t('home.bodythree.but')}</Button>
                    </div>
                </div>
            </div>
            <div className='content-four-four'>
                <div className='content-row'>
                    <div className='title'>{t('home.bodyfour.title')}</div>
                    <div className='alter'>{t('home.bodyfour.alter')}</div>
                    <div className='content'>
                        <div className='image-box'>
                            <div className='box'>
                                <img src={generate_images_bg_1} alt="" />
                                {/* <div>{'Choose the mode forStyleID, FaceID, or Duo'}</div> */}
                                <div>{t('home.bodyfour.text1')}</div>
                            </div>
                            <div className='icon'>
                                <RightOutlined />
                            </div>
                            <div className='box'>
                                <img src={generate_images_bg_2} alt="" />
                                {/* <div>{'Select a theme and click Generate'}</div> */}
                                <div>{t('home.bodyfour.text2')}</div>
                            </div>
                            <div className='icon'>
                                <RightOutlined />
                            </div>
                            <div className='box'>
                                <img src={generate_images_bg_3} alt="" />
                                {/* <div>{'Upload your protrait, click Generate to get exciting images'}</div> */}
                                <div>{t('home.bodyfour.text3')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContentFour;
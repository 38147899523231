// src/auth/SilentRenew.jsx
// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

const SilentRenew = () => {
    const auth = useAuth();
    
    useEffect(() => {
        // console.log('Silent renew component rendered', {
        //     hash: window.location.hash,
        //     search: window.location.search,
        //     pathname: window.location.pathname
        // });

        // react-oidc-context 会自动处理 silent renew 回调
        // 不需要手动调用 signinSilentCallback
    }, [auth]);

    return null;
};

export default SilentRenew;
// analytics.ts
export class Analytics {
    static isInitialized = false;

    static init(measurementId) {
        if ( this.isInitialized) {
            // eslint-disable-next-line no-console
            console.warn('GA4 already initialized');
            return;
        }

        // 加载 GA4
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
        document.head.appendChild(script);

        window.dataLayer = window.dataLayer || [];
        window.gtag = function() { window.dataLayer.push(arguments); }
        window.gtag('js', new Date());
        window.gtag('config', measurementId, {
            // 启用增强型会话归因
            session_engagement: true,
            // 启用广告功能以获取更多来源信息
            allow_google_signals: true
        });
        this.isInitialized = true;
    }

    static trackPageView() {
        const referer = document.referrer;
        const source = this.getSourceFromReferer(referer);

        window.gtag('set', 'user_properties', {
            traffic_source: source
        });
        window.gtag('event', 'page_view', {
            page_location: window.location.href,
            page_referrer: referer,
            traffic_source: source
        });
    }

    static getSourceFromReferer(referer) {
        if (!referer) {
            const userAgent = navigator.userAgent.toLowerCase();
            // eslint-disable-next-line no-console
            console.log("User Agent: ", userAgent);
            try {
                // 通过 User-Agent 判断
                if (userAgent.includes('micromessenger')) return 'wechat_app';
                if (userAgent.includes('weibo')) return 'weibo_app';
                if (userAgent.includes('android') || userAgent.includes('ios')) {
                    if (typeof window.Kuaishou !== 'undefined') {
                        return 'kuai_shou_app';
                    } else if (typeof window.Douyin !== 'undefined') {
                        return 'dou_yin_app';
                    } else if (typeof window.Xiaohongshu !== 'undefined') {
                        return 'xiao_hong_shu_app';
                    }
                }
            } catch (error) {
                return 'direct';
            }
            return "direct";
        }
        const url = new URL(referer);
        const domain = url.hostname.toLowerCase();

        // 社交媒体
        if (domain.includes('youtube.com')) return 'youtube_web';
        if (domain.includes('twitter.com') || domain.includes('t.co')) return 'twitter_web';
        if (domain.includes('x.com')) return 'x_web';
        if (domain.includes('facebook.com')) return 'facebook_web';
        if (domain.includes('pinterest.com')) return 'pinterest_web';
        if (domain.includes('discord.com')) return 'discord_web';
        if (domain.includes('open.weixin.qq.com')) return 'wechat_web';
        if (domain.includes('bilibili.com')) return 'bilibili_web';

        if (domain.includes('linkedin.com')) return 'linkedin_web';
        if (domain.includes('instagram.com')) return 'instagram_web';
        if (domain.includes('weibo.com')) return 'weibo_web';
        if (domain.includes('zhihu.com')) return 'zhihu_web';
        if (domain.includes('douyin.com')) return 'douyin_web';
        if (domain.includes('kuaishou.com')) return 'kuaishou_web';
        if (domain.includes('xiaohongshu.com')) return 'xiaohongshu_web';

        // 搜索引擎
        if (domain.includes('google')) return 'google';
        if (domain.includes('bing.com')) return 'bing';
        if (domain.includes('baidu.com')) return 'baidu';
        
        return 'other';
    }

    static sendEvent(eventName, params = {}) {
        if (!this.isInitialized) {
            // eslint-disable-next-line no-console
            console.warn('GA4 unInitialized');
            return;
        }

        try {
            window.gtag('event', eventName, params);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('event sent error:', error);
        }
    }

    static button_logo_click(pagePath, pageTitle) {
        this.sendEvent('navigation', {
            event_label: 'return_to_home',
            from_path: pagePath,
            page_title: pageTitle || document.title
        });
    }

    static button_if_click(pagePath) {
        this.sendEvent('user_share', {
            event_label: 'invite_friends',
            source_page: pagePath
        });
    }

    static button_login_click(pagePath, sharecode) {
        const params = {
            event_label: 'login',
            source_page: pagePath
        };
        
        if ( sharecode ) {
            params.share_code = sharecode;
        }
        
        this.sendEvent('user_auth', params);
    }

    static button_logout_click(pagePath) {
        this.sendEvent('user_auth', {
            event_label: 'logout',
            source_page: pagePath
        });
    }

    //categoryType: styleid,faceid,duo
    static button_categen_click(categoryType, categoryName) {
        this.sendEvent('category_gen', {
            event_label: categoryType,
            category_name: categoryName
        });
    }

    static button_share_click(categoryName, sharecode) {
        this.sendEvent('user_share', {
            event_label: 'share_content',
            category_name: categoryName,
            share_code: sharecode
        });
    }

    static button_saveimage_click(sharecode) {
        this.sendEvent('user_share', {
            event_label: 'save_image',
            share_code: sharecode
        });
    }

    static button_sharelink_click(sharecode) {
        this.sendEvent('user_share', {
            event_label: 'share_link',
            share_code: sharecode
        });
    }

    static button_sharegen_click(sharecode) {
        this.sendEvent('user_share', {
            event_label: 'generate',
            share_code: sharecode
        });
    }

    static button_contactus_click() {
        this.sendEvent('social', {
            event_label: 'contact_us',
        });
    }
}
import React, { useState, useEffect } from 'react';
import {
    // SearchOutlined,
    DownloadOutlined,
    ShareAltOutlined
} from '@ant-design/icons';
import {
    Menu,
    // Input,
    Button,
    Skeleton,
    // Pagination,
    Image,
    Modal,
    message,
} from 'antd';
import './index.css';
// import download from '../../../images/download.png';
import check from '../../../images/check.png';
import none_data_one from '../../../images/none_data_one.png';
import img_load_error from '../../../images/img_load_error.png';
import loading_img1 from '../../../images/loading_img1.png';
import loading_img2 from '../../../images/loading_img2.png';
import warning_title_icon from '../../../images/warning_title_icon.png';
import { GetImagesHistory, DeleteSelectedTask, TaskRegenAPI, GetShareCode } from '../../../api/request.js';
import { useTasks, useTasksDispatch } from '../../../TasksContext/index.js';
import { useAuth } from 'react-oidc-context';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useCatchAsyncError from '../../../hooks/useCatchAsyncError.jsx';
// import LoadingIcon from '../../../components/loader';
import PageLoading from '../../../components/pageLoading/index.jsx';
import { Analytics } from '../../../analytics.js';

const items = [
    // {
    //     label: 'All Models',
    //     key: 'all',
    // },
    // {
    //     label: 'Text To Image',
    //     key: 'text',
    // },
    // {
    //     label: 'Image To Image',
    //     key: 'image',
    // }
];

const MyArtSpace = () => {
    const tasks = useTasks();
    const dispatch = useTasksDispatch();
    const auth = useAuth();
    const navigate = useNavigate();
    const setError = useCatchAsyncError();
    const { t } = useTranslation();
    const [current, setCurrent] = useState('all');
    const [isEdit, setIsEdit] = useState(false);
    const [renderListData, setRenderListData] = useState([]);

    const [notarizeModalOpen, setNotarizeModalOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isLoadingList, setIsLoadingList] = useState(true);

    const handleOk = async () => {
        setConfirmLoading(true);

        try {
            const itemsToDelete = renderListData.filter(item => item.isDelete);
            const tasksToDelete = itemsToDelete.map(item => item.taskid).join(',');
    
            const result = await DeleteSelectedTask({ data: { uid: auth?.user?.profile?.sub, taskids: tasksToDelete },  token: auth?.user?.access_token });
            if (result.status.toLowerCase() === "success") {
                setRenderListData((oldRenderListData) => {
                    const newRenderListData = oldRenderListData.filter(item => !item.isDelete)
                    return newRenderListData
                })
                setNotarizeModalOpen(false);
                setConfirmLoading(false);
            }
        } catch (error) {
            setNotarizeModalOpen(false);
            setConfirmLoading(false);
        }
        cancelDelete()

    };
    const handleCancel = () => {
        setNotarizeModalOpen(false);
        cancelDelete();
    };

    const onClick = (e) => {
        setCurrent(e.key);
    };

    const editClick = () => {
        setIsEdit(true);
    };

    const cancelOnClick = (index) => {
        setRenderListData((oldRenderListData) => {
            const newRenderListData = [...oldRenderListData];
            newRenderListData[index].isDelete = false;
            return newRenderListData;
        });
    };
    const cancelDeleteOnClick = (index) => {
        setRenderListData((oldRenderListData) => {
            const newRenderListData = [...oldRenderListData];
            newRenderListData[index].isDelete = true;
            return newRenderListData;
        });
    };
    const allDelete = () => {
        setRenderListData((oldRenderListData) => {
            const newRenderListData = [...oldRenderListData];
            for (let i = 0; i < newRenderListData.length; i++) {
                newRenderListData[i].isDelete = true;
            }
            return newRenderListData;
        });
    };
    const cancelDelete = () => {
        setRenderListData((oldRenderListData) => {
            const newRenderListData = [...oldRenderListData];
            for (let i = 0; i < newRenderListData.length; i++) {
                newRenderListData[i].isDelete = false;
            }
            return newRenderListData;
        });
        setIsEdit(false)
    };

    const handleDelete = async () => {
        if (auth && auth?.isAuthenticated) {
            setNotarizeModalOpen(true)
        }
    };

    // eslint-disable-next-line no-unused-vars
    const handleDownload = async (task) => {
        try {
            if (auth && auth?.isAuthenticated) {
                const zip = new JSZip();
    
                for (const url of task.url) {
                    const response = await fetch(url);
                    if (!response.ok) {
                        continue;
                    }
                    const blob = await response.blob();
                    zip.file(url.split('/').pop(), blob); 
                }
    
                const currentDate = new Date().toISOString().slice(0, 10)
                const finalFileName = `GoodApp-AI-${task.category}-${currentDate}.zip`; 
    
                zip.generateAsync({ type: 'blob' }).then((content) => {
                    saveAs(content, finalFileName);
                });
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    };
    
    const hasDeletedItems = renderListData.some(item => item.isDelete);

    const getRenderListData = async (props) => {
        const { timeout, init } = props;
        try {
            if (auth?.isAuthenticated) {
                if (init) { setIsLoadingList(true) }
                const getScenesList = await GetImagesHistory({ data: { uid: auth?.user?.profile?.sub }, token: auth?.user?.access_token, timeout });
                if (getScenesList?.data?.code === 1000 && getScenesList?.data?.data) {
                    const newList = JSON.parse(JSON.stringify(getScenesList.data.data));
                    const userLoadingNum = newList.filter(item => item.image_status === 1 || item.image_status === 2).length;
                    dispatch({
                        type: 'set_user_loading_num',
                        data: userLoadingNum
                    });
                    newList.sort((a, b) => {
                        try {
                            const aDate = new Date(a.starttime.replace(' ', 'T') + 'Z');
                            const bDate = new Date(b.starttime.replace(' ', 'T') + 'Z');
                            const aTimestamp = aDate.getTime();
                            const bTimestamp = bDate.getTime();
                            return bTimestamp - aTimestamp;
                        } catch (error) {
                            return -1;
                        }
                    })
                    setRenderListData(newList);
                } else if (getScenesList?.error?.code === 'ERR_NETWORK') {
                    setRenderListData([]);
                    setError(getScenesList?.error);
                } else if (getScenesList?.error?.code === 'ECONNABORTED') {
                    /* empty */
                } else {
                    setRenderListData([]);
                }
                if (init) {setIsLoadingList(false)}
            }
        } catch (error) {
            if (init) {setIsLoadingList(false)}
            // console.log('error', error);
        }
    }

    const stylerSyncRegenAPI = async (taskid) => {
        try {
            if (auth?.user?.access_token && auth?.user?.profile?.sub) {

                const setStylerSyncRegenAPI = await TaskRegenAPI({
                    data: {
                        taskid: taskid,
                        uid: auth?.user?.profile?.sub
                    },
                    token: auth?.user?.access_token
                })
                if (!setStylerSyncRegenAPI.error) {
                    getRenderListData({
                        timeout: 0,
                        init: false
                    });
                }
            }
        } catch (error) { /* empty */ }
    }

    const handleShare = async (props) => {
        const getShareCode = await GetShareCode({
            data: {
                taskid: props.taskid
            },
            token: auth?.user?.access_token
        });
        if (getShareCode.data) {
            try {
                Analytics.button_share_click(props.category, getShareCode.data.sharecode);
                dispatch({
                    type: 'set_share_modal_data',
                    data: {
                        ...props,
                        sharecode: getShareCode.data.sharecode
                    }
                })
                dispatch({
                    type: 'set_share_modal_show',
                    data: true
                })
            } catch (error) { /* empty */ }
        } else {
            message.error(t('myspace.sharemodal.shareerrormessage'))
        }
    }

    useEffect(() => {
        if (auth && auth?.isAuthenticated) {
            getRenderListData({
                timeout: 0,
                init: true
            });
        } else {
            setIsLoadingList(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth?.isAuthenticated]);

    useEffect(() => {
        if (tasks[3].isUserLoadingImage) {
            dispatch({
                type: 'set_is_user_loading_image',
                data: false,
            });
            getRenderListData({
                timeout: 0,
                init: false
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tasks[3].isUserLoadingImage]);

    useEffect(() => {
        let inter = 0;
        if (tasks[3].userLoadingNum > 0) {
            inter = setInterval(() => {
                getRenderListData({
                    timeout: 3000,
                    init: false
                });
            }, 5000);
        } else {
            clearInterval(inter)
        }
        return () => {
            clearInterval(inter)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth?.user?.access_token, auth?.user?.profile?.sub, tasks[3].userLoadingNum]);

    if (isLoadingList) {
        return <PageLoading/>;
    }

    return <div className="artspace">
        {
            renderListData.length > 0 ?
            <div className="title">
                <Menu
                    onClick={onClick}
                    selectedKeys={[current]}
                    mode="horizontal"
                    items={items}
                    className='menu'
                />
                <div className='title-but'>
                    {/* <Input
                        size="large"
                        placeholder="large size"
                        prefix={<SearchOutlined />}
                        className='input'
                    /> */}
                    <div></div>
                    {
                        isEdit ?
                            <div className='delete-box'>
                                <Button type="link" disabled={!hasDeletedItems} style={{ opacity: hasDeletedItems ? 1 : 0.5 }} onClick={ handleDelete }>{t('myspace.deletebut')}</Button>
                                <Button type="link" onClick={allDelete}>{t('myspace.allbut')}</Button>
                                <Button type="link" onClick={cancelDelete}>{t('myspace.cancelbut')}</Button>
                            </div> :
                            <Button onClick={editClick} className='but'>{t('myspace.editbut')}</Button>
                    }
                    <Modal
                        title={<div><img src={warning_title_icon} alt="" /></div>}
                        width={448}
                        open={notarizeModalOpen}
                        onOk={handleOk}
                        confirmLoading={confirmLoading}
                        onCancel={handleCancel}
                        centered
                        maskClosable={false}
                        closable={false}
                        footer={[
                            <Button key="back" onClick={handleCancel}>{t('myspace.deletemodal.cancelbut')}</Button>,
                            <Button key="ok" type="primary" loading={confirmLoading} onClick={handleOk}>{t('myspace.deletemodal.confirmbut')}</Button>
                        ]}
                        className='delete-modal'
                    >
                        <div>{t('myspace.deletemodal.bodytext1')}</div>
                        <div>{t('myspace.deletemodal.bodytext2')}</div>
                    </Modal>
                </div>
            </div> : <></>
        }
        <div className='content'>
            {
                renderListData.length > 0 ? renderListData.map((item, index) => <div className="content-item" key={item.taskid}>
                    {
                        item.image_status === 3 ?
                            <div className="content-item-mobile-terminal">
                                <Image.PreviewGroup>
                                    <div className="container">
                                        <div className='container-image-box'>
                                            <Image
                                                alt={item.category}
                                                src={ item.url && item.url.length > 0 ? item.url[0] : ""}
                                                className="main-image"
                                                placeholder={<div style={{ backgroundColor: 'white', height: '100%' }}><Skeleton.Image active={true} className="main-image-loading"/></div>}
                                            />
                                        </div>
                                        <div className="thumbnail-container">
                                            {
                                                item.url && item.url.length > 1 ?
                                                    item.url.map((ite, i) => {
                                                        if (i === 0) {
                                                            return null
                                                        } else {
                                                            return <div key={ite} className='thumbnail-container-image-box'><Image
                                                                alt={ite}
                                                                src={ite}
                                                                className="thumbnail"
                                                                placeholder={<div style={{ backgroundColor: 'white', height: '100%' }}><Skeleton.Image active={true} className="thumbnail-loading"/></div>}
                                                            /></div>
                                                        }
                                                    }) : <></>
                                            }
                                        </div>
                                    </div> 
                                </Image.PreviewGroup>
                                <div className="content-item-box">
                                    <div>{item.category}</div>
                                    <Button icon={<ShareAltOutlined />} className='but' type="text" onClick={ () => handleShare(item)}></Button>
                                    {/* <Button icon={<DownloadOutlined />} className='but' type="text" onClick={ () => handleDownload(item)}></Button> */}
                                </div>
                            </div>
                       : <></>
                    }
                    {
                        item.image_status === 4 ?
                        <div className="error">
                            <div className='error-message'>
                                <img src={img_load_error} alt="" />        
                                <div>{t('myspace.errormessage')}</div>
                            </div>
                            <div className='content-item-box error-action'>
                                <div>{item.category}</div>
                                <Button type="primary" className='but' onClick={() => stylerSyncRegenAPI(item.taskid)}>{t('myspace.generatebut')}</Button>
                            </div>
                        </div> : <></>
                    }
                    {
                        item.image_status === 1 || item.image_status === 2 ?
                        <div className="loading">
                            <div className='loading-message'>
                                <div className='loading-message-box'>
                                    <img className='loading-img1' src={loading_img1} alt="" />
                                    <img className='loading-img2' src={loading_img2} alt="" />
                                </div>
                                <div>{t('myspace.loadingmessage')}</div>
                            </div>
                            <div className='content-item-box loading-action'>
                                <div>{item.category}</div>
                                <Button icon={<DownloadOutlined />} type="text" disabled></Button>
                            </div>
                        </div> : <></>
                    }
                    {
                        isEdit ?
                            <div className='edit-mask'>
                                {
                                    item.isDelete ?
                                    <img
                                        src={check}
                                        alt=""
                                        className='check'
                                        onClick={() => cancelOnClick(index)}
                                    /> :
                                    <div
                                        className='check'
                                        onClick={() => cancelDeleteOnClick(index)}
                                    ></div>
                                }
                            </div> : <></>
                    }
                </div>) : <div className='none-data'>
                    <img src={none_data_one} alt="" />
                    <div>{t('myspace.nodatatext')}</div>
                    <Button
                        type="primary"
                        shape="round"
                        className='but'
                        onClick={() => navigate('/')}
                    >{t('myspace.nodatabut')}</Button>
                </div>
            }
        </div>
    </div>
};

export default MyArtSpace;
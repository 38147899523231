import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    Skeleton,
    Image,
    // Badge
} from 'antd';
import {
    RightOutlined} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import './index.css';
import hot from '../../../images/hot.png';
import integral_icon from '../../../images/integral_icon.png';
import homeicon from '../../../images/homeicon.png';
import home_styleid_bg from '../../../images/home_styleid_bg.png';
// import slider from '../../../images/slider.png';
// import before from '../../../images/before.jpg';
// import after from '../../../images/after.jpg';
import { Analytics } from '../../../analytics';

const StyleIDList = (props) => {
    const { sceneList, module, type } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();

    const generateClick = (item) => {
        const categoryType = 'styleid';
        const categoryName = item.category;
        Analytics.button_categen_click(categoryType, categoryName);
        navigate(`/styleid?name=${item.category}&type=${type}`);
    }

    // 拖动
    const [, setPosition] = useState({ x: 0, y: 0 })
    const [defaultCenterValue, setDefaultCenterValue] = useState(0)
    const [isDragging, setIsDragging] = useState(false)
    const [startPos,] = useState({ x: 0, y: 0 })
    const parentRef = useRef(null)
    const childRef = useRef(null)
    useEffect(() => {
        // 初始化子元素位置到父元素中心
        if (parentRef.current && childRef.current) {
            const parentWidth = parentRef.current.offsetWidth
            const childWidth = childRef.current.offsetWidth
            setPosition({ x: (parentWidth - childWidth) / 2, y: 0 });
            setDefaultCenterValue((parentWidth - childWidth) / 2);
        }
    }, [])
    
    useEffect(() => {
        const handleMouseMove = (e) => {
            e.stopPropagation();
            if (!isDragging) return
            
            const parentRect = parentRef.current?.getBoundingClientRect()
            const childRect = childRef.current?.getBoundingClientRect()
            
            if (parentRect && childRect) {
                let newX = e.clientX - parentRect.left - startPos.x
                const isInsideElement =
                    e.clientX >= parentRect.left &&
                    e.clientX <= parentRect.right &&
                    e.clientY >= parentRect.top &&
                    e.clientY <= parentRect.bottom;
                if (isInsideElement) {
                    // 限制子元素在父元素内移动
                    newX = Math.max(-childRect.width / 2, Math.min(newX, parentRect.width - childRect.width / 2 - 2))
                    // console.log('newX', newX);
                    // //鼠标相对视口左边距
                    // console.log('e.clientX', e.clientX);
                    // //子元素相对视口左边距
                    // console.log('parentRect.left', parentRect.left);
                    // //子元素X轴位置
                    // console.log('startPos.x', startPos.x);
                    // //父元素盒子宽度
                    // console.log('parentRect.width', parentRect.width);
                    // //滑块宽度
                    // console.log('childRect.width', childRect.width);
                    
                    setPosition(prev => ({ ...prev, x: newX }))
                } else {
                    setIsDragging(false);
                }
            }
        }
    
        const handleMouseUp = () => {
            setIsDragging(false)
        }
    
        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove)
            window.addEventListener('mouseup', handleMouseUp)
        }
    
        return () => {
            window.removeEventListener('mousemove', handleMouseMove)
            window.removeEventListener('mouseup', handleMouseUp)
        }
    }, [isDragging, startPos])
    
    // const handleMouseDown = (e) => {
    //     e.stopPropagation();
    //     setIsDragging(true)
    //     const childRect = childRef.current?.getBoundingClientRect()
    //     if (childRect) {
    //         setStartPos({
    //             x: e.clientX - childRect.left,
    //             y: e.clientY - childRect.top
    //         })
    //     }
    // }

    //初始化拖拽默认动画
    const [increasing, setIncreasing] = useState(true)
    const [animationComplete, setAnimationComplete] = useState(false)
    
    useEffect(() => {
        if (animationComplete) return

        const interval = setInterval(() => {
            setPosition((prevValue) => {
                if (increasing) {
                    if (prevValue.x <= 1) {
                        setIncreasing(false)
                        return { x: 2, y: prevValue.y }
                    }
                    return { x: prevValue.x - 2, y: prevValue.y }
                } else {
                    if (prevValue.x >= defaultCenterValue) {
                        setAnimationComplete(true)
                        clearInterval(interval)
                        return { x: defaultCenterValue, y: prevValue.y }
                    }
                    return { x: prevValue.x + 2, y: prevValue.y }
                }
            })
        }, 10) // 每10毫秒更新一次，可以根据需要调整
    
        return () => clearInterval(interval)
    }, [increasing, animationComplete, defaultCenterValue])

    return <div className="app-styleid-box" key={module}>
        <div className="app-styleid-header">
            <img src={homeicon} alt="" onClick={() => navigate(`/`)} />
            <div><RightOutlined /></div>
            <div>{t('app.styleid.title')}</div>
        </div>
        <div className='content'>
            <div className='content-row'>
                <div ref={parentRef} className={`content-row-img`}>
                    {/* <img src={before} alt="" />
                    <img
                        style={{
                            clipPath: `inset(0px 0px 0px calc(${position.x}px + clamp(10px, 5rem, 50px)))`,
                        }}
                        src={after}
                        alt=""
                    />
                    <div
                        className='line'
                        style={{ 
                            left: `calc(${position.x}px + clamp(10px, 5rem, 50px))`,
                        }}
                    ></div>
                    <div
                        className='slider'
                        ref={childRef}
                        style={{ 
                            left: `${position.x}px`,
                        }}
                        onMouseDown={handleMouseDown}
                    ><img src={slider} alt="" /></div>
                    <div className='before'>{"Before"}</div>
                    <div className='after'>{"After"}</div> */}
                    <img src={home_styleid_bg} alt="" />
                </div>
                <div className={`content-row-text`}>
                    <h2>{t('app.styleid.title')}</h2>
                    <div>
                        <p><strong>{t('app.styleid.introduction')}</strong></p>
                        <p>{t('app.styleid.body')}</p>
                        <p>{t('app.styleid.conclusion')}</p>
                    </div>
                </div>
            </div>
        </div>
        {
            sceneList.length > 0 ? sceneList.map((item) => <div className="app-styleid-box-item" key={item.category}>
                <div className="app-item" onClick={() => generateClick(item)}>
                    <Image
                        alt={item.category}
                        src={item.scenes[0].imageurl}
                        className="app-item-img"
                        preview={false}
                        placeholder={<div
                            style={{
                                backgroundColor: 'white',
                                borderRadius: '16px',
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            <Skeleton.Image active={true} className="app-item-img" />
                        </div>}
                    />
                    <Image
                        alt={item.category}
                        src={item.scenes[1] ? item.scenes[1].imageurl : item.scenes[0].imageurl }
                        className="app-item-img-two"
                        preview={false}
                        placeholder={<div
                            style={{
                                backgroundColor: 'white',
                                borderRadius: '16px',
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            <Skeleton.Image active={true} className="app-item-img" />
                        </div>}
                    />
                </div>
                <div className="app-item-box">
                    <div className="app-item-styler">
                        <div>{item.category}</div>
                        {
                            item.hot ? <img src={hot} alt="" /> : <></>
                        }
                    </div>
                    <div className="app-item-action">
                        <div className="app-item-integral">
                            <img src={integral_icon} alt="" />
                            <div>{item.point}</div>
                        </div>
                        <Button type="primary" shape="round" className='but' onClick={() => generateClick(item)}>
                            {t('app.generatebut')}
                        </Button>
                    </div>
                </div>
            </div>) : <></>
        }
      
    </div>
}

export default StyleIDList;
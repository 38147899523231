import React, { useRef } from "react";
import './index.css';
import {
    Button,
    Modal,
    QRCode,
    message
} from 'antd';
import {
    CloseOutlined
} from '@ant-design/icons';
import html2canvas from 'html2canvas';
import { useTranslation } from 'react-i18next';
import { useTasks, useTasksDispatch } from '../../TasksContext';
import { LOGOTEXT, LOGOBETA } from '../../constants';
import logo from '../../images/logo.png';
import { Analytics } from '../../analytics';

const ShareModal = () => {
    const { t } = useTranslation();
    const tasks = useTasks();
    const dispatch = useTasksDispatch();
    const canvaRef = useRef(null);
    const handleCancel = () => {
        dispatch({
            type: 'set_share_modal_data',
            data: {}
        })
        dispatch({
            type: 'set_share_modal_show',
            data: false
        })
    };
    const saveImage = () => {
        try {
            Analytics.button_saveimage_click(tasks[8].shareModalData.sharecode);
            const element = canvaRef.current;
            const options = {
                allowTaint: true,
                useCORS: true,
                backgroundColor: null
            };
            if (element) {
                html2canvas(element, options)
                    .then((canvas) => {
                        const link = document.createElement('a');
                        link.href = canvas.toDataURL('image/png');
                        link.download = 'share-image.png';
                        link.click();
                    })
                    .catch((error) => {
                        // eslint-disable-next-line no-console
                        console.error('Error generating image:', error);
                    });
            }
        } catch (error) { /* empty */ }
    }
    const handleCopy = async () => {
        try {
            Analytics.button_sharelink_click(tasks[8].shareModalData.sharecode);
            await navigator.clipboard.writeText(t('myspace.sharemodal.copytext', { url: process.env.REACT_APP_GOODAPP_SHARE_PRXFIX, sharecode: tasks[8].shareModalData?.sharecode }));
            message.success(t('myspace.sharemodal.copysucceedmessage'));
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error("copy failure:", err);
        }
    };

    return <Modal
        closeIcon={<CloseOutlined className="close-icon" />}
        open={tasks[8].shareModalShow}
        centered={true}
        footer={null}
        maskClosable={false}
        onCancel={handleCancel}
        destroyOnClose={true}
        wrapClassName="share-modal"
    >
        {
            tasks[8].shareModalData?.url?.length > 0 ?
            <div className="images" ref={canvaRef}>
                <div className="header">
                    <div className="logo">
                        <img className="img" src={logo} alt="" />
                        <div>{LOGOTEXT}</div>
                        <div>{LOGOBETA}</div>
                    </div>
                </div>
                <div className="content">
                    {
                        tasks[8].shareModalData?.url?.length === 1 ?
                            <img className="img-one" src={tasks[8].shareModalData?.url[0] + "?" + "goodapp"} alt="" crossOrigin="anonymous" /> : <></>
                    }
                    {
                        tasks[8].shareModalData?.url?.length === 2 ?
                            <div className="img-two">
                                <img src={tasks[8].shareModalData?.url[0] + "?" + "goodapp"} alt="" crossOrigin="anonymous" />
                                <img src={tasks[8].shareModalData?.url[1] + "?" + "goodapp"} alt="" crossOrigin="anonymous" />
                            </div>
                        : <></>
                    }
                    {
                        tasks[8].shareModalData?.url?.length === 3 || tasks[8].shareModalData?.url?.length === 4 ?
                            <div className="img-four">
                                {
                                    tasks[8].shareModalData?.url.map((item) => <img src={item + "?" + "goodapp"} key={item} alt={item} crossOrigin="anonymous" />)
                                }
                            </div> : <></>
                    }
                </div>
                <div className="footer">
                    <div>
                        <div>{tasks[8].shareModalData.category}</div>
                        <div>{t('myspace.sharemodal.contentfootertext')}</div>
                    </div>
                    <QRCode bgColor="#ffffff" size={ tasks[2].windowWidth < 768 ? 80 : 100} value={`${process.env.REACT_APP_GOODAPP_SHARE_PRXFIX}/share?sharecode=${tasks[8].shareModalData.sharecode}`} />
                </div>
            </div> : <></>
        }
        <div className="but">
            <Button onClick={saveImage}>{t('myspace.sharemodal.saveimagebut')}</Button>
            <Button onClick={handleCopy}>{t('myspace.sharemodal.sharelinkbut')}</Button>
        </div>
    </Modal>
}

export default ShareModal;
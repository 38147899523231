// eslint-disable-next-line no-unused-vars
import React from 'react';
import { useSearchParams, Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
    const [searchParams] = useSearchParams();
    const type = searchParams.get("type") || null;
    const name = searchParams.get("name") || null;

    if (!type || !name) {
        return <Navigate to="/" />;
    }
    return children;
};

export default ProtectedRoute;
import React from "react";
import { Button } from 'antd';
import {
    useLocation,
    // useNavigate
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './index.css';
// import { useTasks, useTasksDispatch } from '../../TasksContext';
import logo from '../../images/logo.png';
// import { useAuth } from 'react-oidc-context';
import { LOGOTEXT, EMAIL } from '../../constants';
import { Analytics } from '../../analytics';

const MyFooter = () => {
    const location = useLocation();
    const { t } = useTranslation();
    // const auth = useAuth();
    // const navigate = useNavigate();
    // const tasks = useTasks();
    // const dispatch = useTasksDispatch();
    if (location.pathname === '/toc' || location.pathname === '/privacy') {
        return null;
    }
    const contactUsClick = () => {
        // if (auth?.isAuthenticated) {
        //     navigate ('/contactus')
        // } else {
        //     const currentUrl = "/contactus";
        //     sessionStorage.setItem('redirectUrl', currentUrl);
        //     if (tasks[6].isEver) {
        //         auth.signinRedirect();
        //     } else {
        //         dispatch({
        //             type: 'set_login_intercept_modal',
        //             data: {
        //                 isModal: true,
        //                 isEver: tasks[6].isEver,
        //                 currentUrl: currentUrl
        //             }
        //         });
        //     }
        // }
        // const subject = "Hello"; 
        // const body = "This is a test email."; 

        // const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        Analytics.button_contactus_click();
        const mailtoLink = `mailto:${EMAIL}`;
        window.location.href = mailtoLink
    };

    return <div className="footer-heart">
        <div className="left">
            <div className="logo">
                <img src={logo} alt="" />
                <div>{LOGOTEXT}</div>
            </div>
            <div>
                <div>{t('footer.copyrightnotice')}</div>
            </div>
        </div>
            
        <Button type="link" onClick={contactUsClick}>{t('footer.contactus')}</Button>
    </div>
}

export default MyFooter;
import React from "react";
import {
    Button,
    Skeleton,
    Image,
    Typography
} from 'antd';
import {
    RightOutlined,
} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import './index.css';
import hot from '../../../images/hot.png';
import integral_icon from '../../../images/integral_icon.png';
import homeicon from '../../../images/homeicon.png';
import home_duo_bg from '../../../images/home_duo_bg.png';
import { Analytics } from '../../../analytics';

const { Text } = Typography;

const DuoList = (props) => {
    const { sceneList, module, type } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();

    const generateClick = (item) => {
        const categoryType = 'duo';
        const categoryName = item.category;
        Analytics.button_categen_click(categoryType, categoryName);
        navigate(`/duo?name=${item.category}&type=${type}`);
    }

    return <div className="app-duo-box" key={module}>
        <div className="app-duo-header">
            <img src={homeicon} alt="" onClick={() => navigate(`/`)} />
            <div><RightOutlined /></div>
            <div>{t('app.duo.title')}</div>
        </div>
        <div className='content'>
            <div className='content-row'>
                <div className={`content-row-img`}>
                    <img src={home_duo_bg} alt="" />
                </div>
                <div className={`content-row-text`}>
                    <h2>{t('app.duo.bodytitle')}</h2>
                    <div>
                        <p><strong>{t('app.duo.introduction')}</strong></p>
                        <p>{t('app.duo.body')}</p>
                        <p>{t('app.duo.conclusion')}</p>
                    </div>
                </div>
            </div>
        </div>
        {
            sceneList.length > 0 ? sceneList.map((item) => <div className="app-duo-box-item" key={item.category}>
                <div className="app-item" onClick={() => generateClick(item)}>
                    <Image
                        alt={item.category}
                        src={item.scenes[0].imageurl}
                        className="app-item-img"
                        preview={false}
                        placeholder={<div
                            style={{
                                backgroundColor: 'white',
                                borderRadius: '16px',
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            <Skeleton.Image active={true} className="app-item-img" />
                        </div>}
                    />
                    <Image
                        alt={item.category}
                        src={item.scenes[1] ? item.scenes[1].imageurl : item.scenes[0].imageurl }
                        className="app-item-img-two"
                        preview={false}
                        placeholder={<div
                            style={{
                                backgroundColor: 'white',
                                borderRadius: '16px',
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            <Skeleton.Image active={true} className="app-item-img" />
                        </div>}
                    />
                </div>
                <div className="app-item-box">
                    <div className="app-item-styler">
                        <Text ellipsis={{tooltip: item.category}}>{item.category}</Text>
                        {
                            item.hot ? <img src={hot} alt="" /> : <></>
                        }
                    </div>
                    <div className="app-item-action">
                        <div className="app-item-integral">
                            <img src={integral_icon} alt="" />
                            <div>{item.point}</div>
                        </div>
                        <Button type="primary" shape="round" className='but' onClick={() => generateClick(item)}>
                            {t('app.generatebut')}
                        </Button>
                    </div>
                </div>
            </div>) : <></>
        }
    </div>
}

export default DuoList;
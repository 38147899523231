import React, { useEffect, useState } from "react";
import './index.css';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import {
    Button,
    QRCode,
} from 'antd';
import { useTasks, useTasksDispatch } from '../../TasksContext';
import { LOGOTEXT, LOGOBETA } from '../../constants';
import logo from '../../images/logo.png';
import { GetShareCodeInfo } from '../../api/request';
import { Analytics } from '../../analytics';

const Share = () => {
    const { t } = useTranslation();
    const auth = useAuth();
    const tasks = useTasks();
    const navigate = useNavigate();
    const dispatch = useTasksDispatch();
    const [searchParams] = useSearchParams();
    const [data, setData] = useState();
    const sharecode = searchParams.get("sharecode") || '';

    const toGenerate = () => {
        if (data) {
            if (auth?.isAuthenticated) {
                let routerpath = 'styleid';
                if (data.type === 1) {
                    routerpath = 'styleid'
                } else if (data.type === 2) {
                    routerpath = 'faceid'
                } else if (data.type === 3) {
                    routerpath = 'duo'
                }
                Analytics.button_sharegen_click(sharecode);
                navigate(`/${routerpath}?name=${data.category}&type=${data.type}`)
            } else {
                const currentUrl = `/share?sharecode=${sharecode}`;
                sessionStorage.setItem('redirectUrl', currentUrl);
                if (tasks[6].isEver) {
                    auth.signinRedirect();
                } else {
                    dispatch({
                        type: 'set_login_intercept_modal',
                        data: {
                            isModal: true,
                            isEver: tasks[6].isEver,
                            currentUrl: currentUrl
                        }
                    });
                }
            }
        }
    };

    useEffect(() => {
        (async() => {
            const getShareCodeInfo = await GetShareCodeInfo({
                data: { sharecode }
            })
            if (getShareCodeInfo.data) {
                setData(getShareCodeInfo.data);
            } else {
                navigate(`/`);
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sharecode]);

    return <div className="share">
        {
            data?.url?.length > 0 ?
            <div className="images">
                <div className="header">
                    <div className="logo">
                        <img className="img" src={logo} alt="" />
                        <div>{LOGOTEXT}</div>
                        <div>{LOGOBETA}</div>
                    </div>
                </div>
                <div className="content">
                    {
                        data?.url?.length === 1 ?
                            <img className="img-one" src={data.url[0]} alt="" /> : <></>
                    }
                    {
                        data?.url?.length === 2 ?
                            <div className="img-two">
                                <img src={data.url[0]} alt="" />
                                <img src={data.url[1]} alt="" />
                            </div>
                        : <></>
                    }
                    {
                        data?.url?.length === 3 || data?.url?.length === 4 ?
                            <div className="img-four">
                                {
                                    data?.url.map((item) => <img src={item} key={item} alt={item} />)
                                }
                            </div> : <></>
                    }
                </div>
                <div className="footer">
                    <div>
                        <div>{data.category}</div>
                        <div>{t('share.sharetext')}</div>
                    </div>
                    <QRCode bgColor="#ffffff" size={ tasks[2].windowWidth < 768 ? 80 : 100} value={`${process.env.REACT_APP_GOODAPP_SERVICE_URL}/share?sharecode=${sharecode}`} />
                </div>
            </div> : <></>
        }
        <div className="but">
            <Button onClick={toGenerate}>{t('share.generatebut')}</Button>
        </div>
    </div>
};

export default Share;